const customTooltip = function(tooltip) {
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.className = 'custom-chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    this._chart.canvas.parentNode.appendChild(tooltipEl);
  }
  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }
  // Set caret Position
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.backgroundColor = '#FFFFFF';
  tooltipEl.style.borderRadius = '4px';
  tooltipEl.style.border = '1px solid #00ACDC';
  tooltipEl.style.zIndex = '10';
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltip.yAlign) {
    tooltipEl.classList.add(tooltip.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }
  function getBody(bodyItem) {
    return bodyItem.lines.map((line) => {
      const lineTitle = line.split(': ');
      return `<span class="number">${lineTitle[1]}</span><span class="name">${lineTitle[0]}</span>`;
    });
  }
  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(getBody);
    let innerHtml = '<thead>';
    titleLines.forEach(function(title) {
      innerHtml += '<tr class="chart-tooltip-title-row"><th>' + title + '</th></tr>';
    });
    innerHtml += '</thead><tbody>';
    bodyLines.forEach(function(body, i) {
      const colors = tooltip.labelColors[i];
      const canvas = document.createElement('canvas');
      canvas.width = '10';
      canvas.height = '10';
      canvas.style.borderRadius = '50%';
      canvas.style.width = '8px';
      canvas.style.height = '8px';
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = colors.backgroundColor;
      ctx.fillRect(0, 0, 10, 10);
      const img = `<img class="color-dot" src="${canvas.toDataURL()}" />`
      innerHtml += '<tr class="item-row"><td>' + img + body + '</td></tr>';
    });
    innerHtml += '</tbody>';
    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }
  const positionY = this._chart.canvas.offsetTop;
  const positionX = this._chart.canvas.offsetLeft;
  // Display, position, and set styles for font

  const offsetX = document.getElementById('chartjs-tooltip').clientWidth / 2;

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX - offsetX + tooltip.caretX + 'px';
  // tooltipEl.style.top = positionY + 10 + tooltip.caretY + 'px';
  tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
  tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
  tooltipEl.style.padding = tooltip.yPadding +
  'px ' +
  tooltip.xPadding +
  'px';
};

export {
  customTooltip,
}